import React from 'react';
import './css/zodiac-sign.css';

import Moment from "react-moment";
import api, { baseUrl } from '../api/api';
const ZodiacSigns = ({data}) => {
    return (
        <>
            <div className="sign_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="titleSec_wrapper">
                                <h2 className='heading'>Choose Your <span>Zodiac Sign</span></h2>
                                <h4 className='animatedLine'> <span>&nbsp;</span> </h4>
                                <p className='desc'>Zodiac signs are astrological symbols representing personality traits based on birthdates; <br />influencing relationships, careers, and more.</p>
                            </div>
                        </div>

                        {data.map((item, index) => (
                            <div className="col-lg-2 col-md-3 col-sm-4 col-6" align="center" key={index}>
                                <div className="sign_box">
                                    <div className="sign_box_img">
                                        <img src={baseUrl + item.image} alt="icon1" />
                                    </div>
                                    <div className="sign_box_cont">
                                        <div className='signName'>{item.name}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ZodiacSigns;
