import {Link} from 'react-router-dom';
const Breadcrumb = ({pageTitle}) => {
    return (
        <section className="as_breadcrum_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h1>{pageTitle}</h1>

                        <ul className="breadcrumb">
                            <li><Link to="/home">Home</Link></li>
                            <li>{pageTitle}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Breadcrumb;