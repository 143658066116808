import React, {useState, useEffect} from 'react';

import BannerSlider from './banner-slider';
import ZodiacSigns from './zodiac-sign';
import Services from './services';
import AboutSection from './about-us-sec';
import VastuService from './vastu-services';
import TestimonialSlider from './testimonial-section';
import Consultency from './our-consultency';
import OurAstrologer from '../components/our-astrologer';
import BlogSec from './blog-section';
import AboutVastu from './about-vastu';

import api, { baseUrl } from '../api/api';
import axios from 'axios';
const Home = () => {
    const [consultency, setConsultency] = useState([]);
    const [sign, setSign] = useState([]);
    const [team, setTeam] = useState([]);
    const [blog, setBlog] = useState([]);
    const [fetchRecord] = useState(3);
    const [startIndex] = useState(0);
    const fetchConsultency = async () => {
        await api.post("/activeAstrologerCategory")
        .then((resp) => {
            setConsultency(resp.data.recordList);
        })
    }
    const fetchSign = async () => {
        await api.post("/activeHororscopeSign")
        .then((resp) => {
            setSign(resp.data.recordList);
        })
    }
    const fetchTeam = async () => {
        await api.post("/getAstrologer",)
        .then((resp) => {
            setTeam(resp.data.recordList);
        })
    }
    const fetchBlog = async () => {
        await api.post("/activeBlogs",
            {
                startIndex: startIndex,
                fetchRecord: fetchRecord
            })
        .then((resp) => {
            setBlog(resp.data.recordList);
        })
    }
    useEffect(()=>{
        fetchConsultency();
        fetchSign();
        fetchBlog();
        fetchTeam();
    }, [])
    return (
        <div className="main_header_wrapper">

            <BannerSlider />
            <AboutVastu />
            <VastuService />
            <AboutSection />
            <ZodiacSigns data={sign}/>
            <Services />
            <TestimonialSlider />

            <Consultency data={consultency}/>
            <OurAstrologer data={team}/>
            <BlogSec data={blog}/>
        </div>
    )
}
export default Home;