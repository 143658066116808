import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import './index.css';
import Breadcrumb from '../components/breadcrumb';

import axios from 'axios';
import api from '../api/api';
const Contact = () => {
    const [formData, setformData] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        message:'',
    });
    const [errors, setErrors] = useState({})
    const handlechange = (name, value) =>{
        setformData((pre) =>({
            ...pre,
            [name]: value,
        }))
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const validationError =  validateForm(formData);
        if(Object.keys(validationError).length > 0){
            setErrors(validationError);
        }else{
            api.post('/createenquiries', formData).then((resp) => {
                
            if(resp.data.status){
                setformData({
                    name: '',
                    phone: '',
                    email: '',
                    subject: '',
                    message:'',
                });
                toast(resp.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }
                else{
                    toast.error({ type: 'error' }, {
                        position: toast.POSITION.TOP_RIGHT,
                      })
                }
            }).catch((err) => {
                toast.error(err, {
                  position: toast.POSITION.TOP_RIGHT,
                })
              })
        }
    }

    function validateForm(formData){
        const errors = {};
        if(!formData.name.trim()){
            errors.name = "Name is required";
        }
        if(!formData.phone.trim()){
            errors.phone = "Mobile no is required";
        }
        if(!formData.email.trim()){
            errors.email = "Email is required";
        }
        if(!formData.subject.trim()){
            errors.subject = "Subject is required";
        }
        if(!formData.message.trim()){
            errors.message = "Message is required";
        }
        return errors;
    }
    const data = {
        pageTitle: "Contact Us"
    }
    return (
        <div>
            <ToastContainer></ToastContainer>
            <Breadcrumb {...data} />

            <div className="contact_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="titleSec_wrapper">
                                <h2 className='heading'>Get in <span>Touch</span></h2>
                                <h4 className='animatedLine'><span>&nbsp;</span></h4>
                                <p className='desc'>Reach out for inquiries and assistance. Contact us today for any questions or collaboration opportunities. <br /> We look forward to connecting</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className='contact_info_box'>
                                <div className="contact_info mb-3">
                                    <i className="fa fa-map-marker"></i>
                                    <div className="inner_wrap">
                                        <h4><Link to="#">Address</Link></h4>
                                        <p>123 Main Street, Citytown, Stateville 12345, Countryland</p>
                                    </div>
                                </div>
                                <div className="contact_info mb-3">
                                    <i className="fa fa-phone"></i>
                                    <div className="inner_wrap">
                                        <h4><Link to="tel:9999949175">Phone</Link></h4>
                                        <p>+91-9999949175</p>
                                    </div>
                                </div>
                                <div className="contact_info">
                                    <i className="fa fa-envelope"></i>
                                    <div className="inner_wrap">
                                        <h4><Link to="#">Email</Link></h4>
                                        <p>hello@astromaya.in<br /> info@astromaya.in</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <Form className="contactForm" onSubmit={handleSubmit}>
                                <Container>
                                    <Row className="mb-3">
                                        <Col xxl={6} xl={6} lg={6} xs={6} className=''>
                                            <Form.Group className=" mb-3">
                                                <Form.Control type="text" name="name" placeholder='Name' value={formData.name} onChange={(e) => handlechange('name', e.target.value)} required />
                                            </Form.Group>
                                            {errors.name && <span className="text-danger">{errors.name}</span>}
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} xs={6} className=''>
                                            <Form.Group className=" mb-3">
                                                <Form.Control type="email" name="email" placeholder='Email' value={formData.email} onChange={(e) => handlechange('email', e.target.value)} required />
                                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                                
                                            </Form.Group>
                                        </Col> 
                                        <Col xxl={6} xl={6} lg={6} xs={6} className=''>
                                            <Form.Group className=" mb-3">
                                                <Form.Control type="number" name="phone" placeholder='Phone' value={formData.phone} onChange={(e) => handlechange('phone', e.target.value)} required />
                                                {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                                
                                            </Form.Group>
                                        </Col>                                        
                                        <Col xxl={6} xl={6} lg={6} xs={6} className=''>
                                            <Form.Group className=" mb-3">
                                                <Form.Control name="subject" placeholder='Subject' value={formData.subject} onChange={(e) => handlechange('subject', e.target.value)} required />
                                                {errors.subject && <span className="text-danger">{errors.subject}</span>}
                                            </Form.Group>
                                        </Col>                                                                                
                                        <Col xxl={12} xl={12} lg={12} xs={12} className=''>
                                            <Form.Group className=" mb-3">
                                                <Form.Control as="textarea" placeholder='Message' rows={3} name="message" value={formData.message} onChange={(e) => handlechange('message', e.target.value)} required />
                                                {errors.message && <span className="text-danger">{errors.message}</span>}
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} xs={12}>
                                            <div class="d-flex justify-content-center">
                                               <Button type="submit" class="contactBtn">Send Message</Button>    
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;

