import React from 'react';
import { Link } from 'react-router-dom';
import './css/tarot-card.css';
import './css/common.css';
import Breadcrumb from '../components/breadcrumb';

const TarotCard = () => {
    const data = {
        pageTitle: "Tarot Card"
    }
    const TarotCard = [
        {
            id: 1,
            img: "/images/tarot.jpg",
            title: 'about this card',
            desc: 'Unlock the mystical insights of Tarot cards. Our skilled readers guide you through personalized readings, offering profound revelations about your past, present, and future. Embrace the wisdom of the cards for clarity and enlightenment on lifes journey',
            tarotName: 'The Fool'
        },
        {
            id: 2,
            img: "/images/tarot.jpg",
            title: 'about this card',
            desc: 'Unlock the mystical insights of Tarot cards. Our skilled readers guide you through personalized readings, offering profound revelations about your past, present, and future. Embrace the wisdom of the cards for clarity and enlightenment on lifes journey',
            tarotName: 'The Magician'
        },
        {
            id: 3,
            img: "/images/tarot.jpg",
            title: 'about this card',
            desc: 'Unlock the mystical insights of Tarot cards. Our skilled readers guide you through personalized readings, offering profound revelations about your past, present, and future. Embrace the wisdom of the cards for clarity and enlightenment on lifes journey',
            tarotName: 'The High Priestess'
        },
        {
            id: 4,
            img: "/images/tarot.jpg",
            title: 'about this card',
            desc: 'Unlock the mystical insights of Tarot cards. Our skilled readers guide you through personalized readings, offering profound revelations about your past, present, and future. Embrace the wisdom of the cards for clarity and enlightenment on lifes journey',
            tarotName: 'The Empress'
        },
        {
            id: 5,
            img: "/images/tarot.jpg",
            title: 'about this card',
            desc: 'Unlock the mystical insights of Tarot cards. Our skilled readers guide you through personalized readings, offering profound revelations about your past, present, and future. Embrace the wisdom of the cards for clarity and enlightenment on lifes journey',
            tarotName: 'The Emperor'
        },
        {
            id: 6,
            img: "/images/tarot.jpg",
            title: 'about this card',
            desc: 'Unlock the mystical insights of Tarot cards. Our skilled readers guide you through personalized readings, offering profound revelations about your past, present, and future. Embrace the wisdom of the cards for clarity and enlightenment on lifes journey',
            tarotName: 'The Hierophant'
        },
        {
            id: 7,
            img: "/images/tarot.jpg",
            title: 'about this card',
            desc: 'Unlock the mystical insights of Tarot cards. Our skilled readers guide you through personalized readings, offering profound revelations about your past, present, and future. Embrace the wisdom of the cards for clarity and enlightenment on lifes journey',
            tarotName: 'The Lovers'
        },
        {
            id: 7,
            img: "/images/tarot.jpg",
            title: 'about this card',
            desc: 'Unlock the mystical insights of Tarot cards. Our skilled readers guide you through personalized readings, offering profound revelations about your past, present, and future. Embrace the wisdom of the cards for clarity and enlightenment on lifes journey',
            tarotName: 'The Chariot'
        }
    ];
    return (
        <div className='tarotPage'>
            <Breadcrumb {...data} />
            <div className='srvPage_wrap'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="serPage_img">
                                <img src="/images/tarot-card.jpg" alt="tarot-card" className="img-fluid rounded-2" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className='title'>Tarot meanings and how to view them</div>
                            <p className='mb-3'>"Explore the mystical world of tarot cards. Gain insights into your past, present, and future. Expert readings for guidance and self-discovery.</p>
                            <p className='mb-3'>Tarot meanings and how to view them It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Dive into the profound world of Tarot meanings. Discover insights on interpreting cards and gaining a deeper understanding of their symbolic significance. Unlock the mysteries within each draw.</p>
                            <p>Discover the profound world of Tarot meanings and learn how to interpret them. Explore insights into life's questions through this ancient and mystical divination practice.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tarotCardSec bgtheme'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center pb-4">
                            <h1 className="as_heading">Tarot Card</h1>
                            <h4 className="animatedLine"><span>&nbsp;</span></h4>
                            <p className=" pb-20">Unlock mystical insights with our Tarot card readings. Delve into personalized guidance and cosmic revelations for a journey of self-discovery and enlightenment.</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="ast_tarot_box_wrapper">
                                <div className="row">
                                    {TarotCard.map((item, index) => (
                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="ast_tarot_box" >
                                                <Link to="#!">
                                                    <img src={item.img} alt="tarot" title="Tarot Card" />
                                                    <div className="terot_content">
                                                        <div className='terotTitle'>{item.title}</div>
                                                        <p>{item.desc}</p>
                                                    </div>
                                                    <div className='tarotName'>{item.tarotName}</div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default TarotCard;
