import React from 'react';
import { Link } from 'react-router-dom';
import './css/service.css';

const Services = () => {
    const services = [
        {
            id: '1',
            img: "/images/vastu-service.jpg",
            title: 'Premium Service - Vaastu',
            desc: 'Enhance harmony and prosperity with personalized Vaastu Premium Services.',
            url: '/vastu'
        },
        {
            id: '2',
            img: "/images/call-astrologer-service.jpg",
            title: 'Call to Astrologer',
            desc: 'Consult with an experienced astrologer for personalized insights and guidance today.',
            url: '/services/call-to-astrologer'
        },
        {
            id: '3',
            img: "/images/chat-astrologer-service.jpg",
            title: 'Chat to Astrologer',
            desc: 'Personalized astrological guidance, insights, and answers for your questions.',
            url: '/services/chat-to-astrologer'
        },
        {
            id: '4',
            img: "/images/kundali-service.jpg",
            title: 'kundali Matching',
            desc: 'Astrological compatibility for harmonious relationships and partnerships.',
            url: '/services/kundli-matching'
        }
    ]
    return (
        <div className="service_wrapper bgtheme">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="titleSec_wrapper">
                            <h2 className='heading'>Our Popular <span>Services</span></h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                            <p className='desc'>Astrologers offer Premium Service - Vaastu, Call to Astrologer, Chat to Astrologer,<br /> and kundali Matching services.</p>
                        </div>
                    </div>
                    {services.map((item, index) => (
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                            <div className="service_box_wrapper">
                                <div className="service_img">
                                    <img src={item.img} alt="img" />
                                </div>
                                <div className="service_content">
                                    <div className='srvTitle'><Link to="#">{item.title}</Link></div>
                                    <p className='srvDesc'>{item.desc}</p>
                                    <div className='btnUrl'><Link to={item.url}>Read More <i className="fa fa-long-arrow-right"></i></Link></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Services;
