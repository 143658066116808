import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <section className="as_footer_wrapper pt-20">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="as_footer_inner pb-3">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12">
                                    <div className="as_footer_widget">
                                        <div className="as_footer_logo mb-2">
                                            <Link to="/"><img src="/images/footer_logo.png" alt="" style={{ height: '150px' }} /></Link>
                                        </div>

                                        <ul className="as_contact_list text-center ms-3">
                                            <li>
                                                <img src="/images/svg/phone.svg" alt="" />
                                                <p> 
                                                    <Link to="tel:9999949175">+91-9999949175</Link>
                                                </p>
                                            </li>
                                        </ul>
                                        <ul className="socialLink">
                                            <li><Link to="#!"><i className="fa fa-facebook"></i></Link></li>
                                            <li><Link to="#!"><i className="fa fa-twitter"></i></Link></li>
                                            <li><Link to="#!"><i className="fa fa-youtube-play"></i></Link></li>
                                            <li><Link to="#!"><i className="fa fa-linkedin"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                                    <div className="as_footer_widget">
                                        <h3 className="as_footer_heading">Services</h3>

                                        <ul>
                                            <li><Link to="/vastu"> <i className="fa fa-caret-right" aria-hidden="true"></i> Vastu Consultaion</Link></li>
                                            <li><Link to="/services/call-to-astrologer"> <i className="fa fa-caret-right" aria-hidden="true"></i> Call to Astrologer</Link></li>
                                            <li><Link to="#!"> <i className="fa fa-caret-right" aria-hidden="true"></i> Chat to Astrologer</Link></li>
                                            <li><Link to="/services/kundli-matching" target="_blank"> <i className="fa fa-caret-right" aria-hidden="true"></i> kundali Matching</Link></li>
                                            <li><Link to="/services/kundli-making"> <i className="fa fa-caret-right" aria-hidden="true"></i> Kundali Making</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                                    <div className="as_footer_widget">
                                        <h3 className="as_footer_heading">Other Menu</h3>

                                        <ul>
                                            <li><Link to="/about"> <i className="fa fa-caret-right" aria-hidden="true"></i> About Us</Link></li>
                                            <li><Link to="/mall"> <i className="fa fa-caret-right" aria-hidden="true"></i> Astro mall</Link></li>
                                            <li><Link to="/blog"> <i className="fa fa-caret-right" aria-hidden="true"></i> Blog </Link></li>
                                            <li><Link to="/privacy-policy"> <i className="fa fa-caret-right" aria-hidden="true"></i> Privacy Policy</Link></li>
                                            <li><Link to="/terms-and-condition"> <i className="fa fa-caret-right" aria-hidden="true"></i> Terms and Conditions</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                                    <div className="as_footer_widget">
                                        <h3 className="as_footer_heading">Download App</h3>

                                        <p>Download Our Mobile App</p>
                                        <div className="download_btn_wrapper">
                                            <Link to="#"><img src="/images/App-Store.png" className="img-fluid mb-2" alt="App Store" /></Link>
                                            <Link to="#"><img src="/images/Google-Play.png" className="img-fluid" alt="Google Play" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="as_copyright_wrapper text-center">
                            <p>
                                © Vercha Jewels LLP 2023 | All Rights Reserved.Design by 
                                <Link to="https://kriscent.in/" target="_blank" className='text-color'> Kriscent Techno Hub Pvt. Ltd</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;

