import React from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const [sticky, changeStickyData] = useState("lower-header hidden-sm hidden-xs");
    window.onscroll = () => {
        let temp = "";
        let top = window.scrollY
        if (top > 100) {
            temp = "lower-header hidden-sm hidden-xs menu_fixed animated fadeInDown";
        }
        else {
            temp = "lower-header hidden-sm hidden-xs";
        }
        return changeStickyData(temp);
    }

    //sidebar
    const [isSidebarMenuOpen, setisSidebarMenuOpen] = useState(false);
    const handleToggleMenu = () => {
        setisSidebarMenuOpen(!isSidebarMenuOpen);
    };
    const closeMenu = () => {
        setisSidebarMenuOpen(false);
    };

    // dropdown submenu
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [isMoveOut, setIsMoveOut] = useState(false);
    const handleToggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
        setIsMoveOut(!isMoveOut);
    };
    const submenuClassName = isSubmenuOpen ? 'cd-secondary-dropdown' : 'cd-secondary-dropdown is-hidden';
    const moveoutClassName = isMoveOut ? 'cd-dropdown-content move-out' : 'cd-dropdown-content';
    const closeSubMenu = () => {
        handleToggleSubmenu(false);
    };


    return (
        <div className="main_header_wrapper">
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-5">
                            <div className='d-flex'>
                                <div className="header_add_wrapper border_icon hidden-sm hidden-xs pe-3 d-none">
                                    <div className="header_add_icon">
                                        <i className="fa fa-home"></i>
                                    </div>
                                    <div className="header_add_icon_cont">
                                        <p>123 Main Street Citytown, Stateville.</p>
                                    </div>
                                </div>
                                <div className="header_add_wrapper phone border_icon">
                                    <div className="header_add_icon last">
                                        <i className="fa fa-phone"></i>
                                    </div>
                                    <div className="header_add_icon_cont">
                                        <p><Link to="tel:9999949175" className="text-white rh">+91-9999949175</Link></p>
                                    </div>
                                </div>
                                <div className="header_add_wrapper email">
                                    <div className="header_add_icon last">
                                        <i className="fa fa-envelope"></i>
                                    </div>
                                    <div className="header_add_icon_cont">
                                        <p><Link to="mailto:info@astromaya" className="text-white rh">info@astromaya.in</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={sticky}>
                <div className="container">
                    <div className='row'>
                        <div className="col-xl-3 col-xl-3 col-lg-2 col-md-3 col-sm-3  header_logo_left">
                            <div className="logo_wrapper">
                                <Link to="/">
                                    <img src="/images/logo.png" className="img-fluid" alt="logo" title="Logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-9 col-xl-9 col-lg-10 col-md-9 col-sm-3">
                            <div className='header_logo_right'>
                                <nav className="main_menu">
                                    <ul>
                                        <li>
                                            <NavLink to="/" className="menu-button">Home</NavLink>
                                        </li>
                                        <li>
                                            <Link to="/about" className="menu-button">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/horoscope" className="menu-button">Horoscope</Link>
                                        </li>
                                        <li className="dropdown menu-button">
                                            <Link to="#" className="menu-button">Services</Link>
                                            <ul className="dropdown-menu mega_menu">
                                                <li>
                                                    <Link to="/services/tarot-card" className="menu-button">Tarot Card</Link>
                                                </li>
                                                <li>
                                                    <Link to="/services/palm" className="menu-button">Palm</Link>
                                                </li>
                                                <li>
                                                    <Link to="/services/numerology" className="menu-button">Numerology</Link>
                                                </li>
                                                <li>
                                                    <Link to="/vastu" className="menu-button">Vastu Consultation</Link>
                                                </li>
                                                <li>
                                                    <Link to="/services/energy-healing" className="menu-button">Energy Healing</Link>
                                                </li>
                                                <li>
                                                    <Link to="/services/call-to-astrologer" className="menu-button">Call to Astrologer</Link>
                                                </li>
                                                <li>
                                                    <Link to="/services/chat-to-astrologer" className="menu-button">Chat to Astrologer</Link>
                                                </li>
                                                <li>
                                                    <Link to="/services/kundli-matching" className="menu-button">kundali Matching</Link>
                                                </li>
                                                <li>
                                                    <Link to="/services/kundli-making" className="menu-button">Kundali Making</Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/mall" className="menu-button">Astro Mall</Link>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="btn_wrapper hidden-md">
                                    <ul>
                                        <li>
                                            <Link to="/contact" className="btn_hover">Enquiry Now</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className={sticky}>
                <div className="top_header_main_Wrapper">
                    <div className="header_logo_left">
                        <div className="logo_wrapper">
                            <Link to="/">
                                <img src="/images/logo.png" className="img-fluid" alt="logo" title="Logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="header_logo_right">
                        <nav className="main_menu">
                            <ul>
                                <li>
                                    <NavLink to="/"  className="menu-button">Home</NavLink>
                                </li>
                                <li>
                                    <Link to="/about" className="menu-button">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/services" className="menu-button">Horoscope</Link>
                                </li>
                                <li className="dropdown menu-button">
                                    <Link to="#" className="menu-button">Services</Link>
                                    <ul className="dropdown-menu mega_menu">
                                        <li>
                                            <Link to="#!" className="menu-button">Vastu</Link>
                                        </li>
                                        <li>
                                            <Link to="#!" className="menu-button">Call to Astrologer</Link>
                                        </li>
                                        <li>
                                            <Link to="#!" className="menu-button">Chat to Astrologer</Link>
                                        </li>
                                        <li>
                                            <Link to="/services/kundli-matching" className="menu-button">kundali Matching</Link>
                                        </li>
                                        <li>
                                            <Link to="/services/kundli-making" className="menu-button">Kundali Making</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/mall" className="menu-button">Mall</Link>
                                </li>
                                <li>
                                    <Link to="/bio" className="menu-button">Bio</Link>
                                </li>
                                <li>
                                    <Link to="#!" className="menu-button">Vastu</Link>
                                </li>
                                <li>
                                    <Link to="/contact" className="menu-button">Contact</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="btn_wrapper hidden-md">
                            <ul>
                                <li>
                                    <Link to="/contact" className="btn_hover">Consultation</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}

            <header className="mobail_menu visible-sm visible-xs">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-6 centerAlign">
                            <div className="logo">
                                <Link to="/"><img src="/images/logo.png" alt="Logo" title="Logo" style={{ width: '118px' }} /></Link>
                            </div>
                        </div>
                        <div className="col-sm-6 col-6">
                            <div className="cd-dropdown-wrapper">
                                <Link className="house_toggle" to="#!" onClick={handleToggleMenu}>
                                    <i className="fa fa-bars" aria-hidden="true"></i>
                                </Link>

                                <nav className={`cd-dropdown ${isSidebarMenuOpen ? 'dropdown-is-active' : ''}`}>
                                    <h2><Link to="/">Astro Maya</Link></h2>
                                    <Link to="#0" className="cd-close" onClick={closeMenu}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </Link>
                                    <ul className={moveoutClassName}>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About US</Link></li>

                                        <li><Link to="/services">Horoscope </Link></li>

                                        <li className="has-children">
                                            <Link to="#!" onClick={handleToggleSubmenu}>Services</Link>

                                            <ul className={submenuClassName}>
                                                <li className="go-back"><Link to="#!" onClick={closeSubMenu}>Menu</Link></li>
                                                <li>
                                                    <Link to="#!">Vastu</Link>
                                                </li>
                                                <li>
                                                    <Link to="#!">Call to Astrologer</Link>
                                                </li>
                                                <li>
                                                    <Link to="#!">Chat to Astrologer</Link>
                                                </li>
                                                <li>
                                                    <Link to="#!">kundali Matching</Link>
                                                </li>
                                                <li>
                                                    <Link to="#!">Kundali Making</Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li><Link to="/mall">Mall</Link></li>

                                        <li><Link to="/bio">Bio</Link></li>

                                        <li><Link to="/vastu">Vastu </Link></li>

                                        <li><Link to="/contact">Contact</Link></li>
                                        {/* <li>
                                        <div className="btn_wrapper hidden-md">
                                            <ul>
                                                <li>
                                                    <Link to="/contact" className="btn_hover">Consultation</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        </li> */}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;

