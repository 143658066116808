import React, {useState, useEffect} from 'react';
import './css/chat-to-astrologer.css';

import api, { baseUrl } from '../api/api';
import axios from 'axios';
import { Link } from 'react-router-dom';

const CallToAstrologer = () => {
    const [team, setTeam] = useState([]);
    const [fetchRecord] = useState(3);
    const [startIndex] = useState(0);
    const fetchTeam = async () => {
        await api.post("/getAstrologer",
            {
                startIndex: startIndex,
                // fetchRecord: fetchRecord
            })
        .then((resp) => {
            setTeam(resp.data.recordList);
        })
    }
    useEffect(()=>{
        fetchTeam();
    }, [])
    return (
        <div className="chatAstroWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="titleSec_main_wrapper">
                            <div className="titleSec_wrapper">
                                <h2>Call to Astrologer</h2>
                                <h4><span>&nbsp;</span></h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='row'>
                            {team.map((team) => (
                                <div className='col-lg-4 col-md-6 col-sm-12 col-12 mb-3'>
                                    <div className='chatAstro'>
                                        <div className='memberPic'>
                                            <img src={baseUrl+team.profileImage} alt="member" />
                                            <div className='calling'>
                                                <i class='fa fa-phone pe-1 text-white'></i> Call
                                            </div>
                                        </div>
                                        <div className='aboutMember'>
                                            <div className='name mb-2'><Link to="/astrologerProfile">{team.name}</Link></div>
                                            <div className='pb-2'>
                                                <div className='role'><i className="fa fa-star pe-1"></i>{team.primarySkill}</div>
                                            </div>
                                            <div className='pb-2 exlan'>
                                                <div className='experience'><i className="fa fa-clock-o pe-1"></i>{team.experienceInYears}+ Years</div>
                                                <div className='language'>
                                                    <i className="fa fa-language pe-1"></i>
                                                    {team.languageKnown}
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div className='charges'><i className="fa fa-inr pe-1"></i>{team.charge}/min</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default CallToAstrologer;
