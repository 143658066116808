import React from "react";
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import './css/kundali-making.css';
import Breadcrumb from '../components/breadcrumb';

const kundliMatching = () => {
    const data = {
        pageTitle: "Kundli Matching"
    }


    return (
        <div className='kundliMaking kundliMatching'>
            <Breadcrumb {...data} />

            <div className='kundliMakingSec'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 text-center titleArea">
                            <h1 className="as_heading">Kundli Matching</h1>
                            <h4 className="animatedLine"><span>&nbsp;</span></h4>
                            <p className="">Kundli matching, a vital aspect of Vedic astrology, assesses compatibility between prospective <br /> life partners based on their birth charts for harmonious marriages.</p>
                        </div>
                        <div className='col-12'>
                            <Form className="form myCard">
                                <Row className="mb-3">
                                    <Col xxl={6} xl={6} lg={6} xs={12}>
                                        <Container>
                                            <div className='matchingName'>Boy Birth Details</div>
                                            <Row className="mb-3 FormMyCard">
                                                <Col xxl={12} xl={12} lg={12} xs={12} className=''>
                                                    <Form.Group className=" mb-3">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control placeholder="Name" name="name" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Day</Form.Label>
                                                        <Form.Control type="text" min={0} placeholder="Days" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Month</Form.Label>
                                                        <Form.Control type="number" placeholder="Month" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Day</Form.Label>
                                                        <Form.Control type="number" placeholder="Year" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Hour</Form.Label>
                                                        <Form.Control type="number" placeholder="Houre" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Minutes</Form.Label>
                                                        <Form.Control type="number" placeholder="Minutes" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Seconds</Form.Label>
                                                        <Form.Control type="number" placeholder="Seconds" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={12} xl={12} lg={12} xs={12}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Place of Birth</Form.Label>
                                                        <Form.Control type="text" placeholder="Place of Birth" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    <Col xxl={6} xl={6} lg={6} xs={12}>
                                        <Container>
                                            <div className='matchingName'>Girl Birth Details</div>
                                            <Row className="mb-3 FormMyCard">
                                                <Col xxl={12} xl={12} lg={12} xs={12} className=''>
                                                    <Form.Group className=" mb-3">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control placeholder="Name" name="name" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Day</Form.Label>
                                                        <Form.Control type="number" min={0} placeholder="Days" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Month</Form.Label>
                                                        <Form.Control type="number" placeholder="Month" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Day</Form.Label>
                                                        <Form.Control type="number" placeholder="Year" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Hour</Form.Label>
                                                        <Form.Control type="number" placeholder="Houre" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Minutes</Form.Label>
                                                        <Form.Control type="number" placeholder="Minutes" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={4} xl={4} lg={4} xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Seconds</Form.Label>
                                                        <Form.Control type="number" placeholder="Seconds" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xxl={12} xl={12} lg={12} xs={12}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Place of Birth</Form.Label>
                                                        <Form.Control type="text" placeholder="Place of Birth" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    <Col xxl={12} xl={12} lg={12} xs={12} className="text-center">
                                        <Button className="as_btn" type="submit">
                                            Match Kundli
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default kundliMatching;   