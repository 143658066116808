import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Breadcrumb from '../components/breadcrumb';

const Bio = () => {
    const now = 75;
    const data = {
        pageTitle : "Brief Description"
    }
    return (
        <div>
            <Breadcrumb {...data} />

            <section className="team-details pt-80 pb-80 bgtheme">
                <div className="container">
                    <div className="team-details-content mb-25">
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                                <figure className="image-box mr-40"><img src="/images/chaaru.jpg" alt="" /></figure>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                                <div className="content-box">
                                    <span className="designation">Co-Founder</span>
                                    <h2>Miss. Chaaru</h2>
                                    <ul className="social-links clearfix">
                                        <li><Link to="#!"><i className="fa fa-facebook-f"></i></Link></li>
                                        <li><Link to="#!"><i className="fa fa-twitter"></i></Link></li>
                                        <li><Link to="#!"><i className="fa fa-instagram"></i></Link></li>
                                    </ul>
                                    <div className="text-box mb-25">
                                        <p>Morbi accumsan mattis nulla faucibus mi lorem libero ornare lectus. Adipiscing
                                            facilisi magna sit amet lobortis. Purus elementum ultrices urna eget id id amet.
                                        </p>
                                        <p>Nam tempor dictumst eget vel purus sollicitudin. Pharetra cursus interdum sed a
                                            scelerisque blandit.</p>
                                    </div>
                                    <ul className="info clearfix">
                                        <li><strong>Expertise:</strong> Strategy, Leadership</li>
                                        <li><strong>Experience:</strong> 8 Years</li>
                                        <li><strong>Email:</strong> <Link
                                            to="mailto:chaaru@gmail.com">chaaru@gmail.com</Link></li>
                                        <li><strong>Phone:</strong> <Link to="tel:913336660021">(+91) 333 666 0021</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="biography mb-80">
                        <h3>Miss. Chaaru</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Libero turpis blandit blandit mauris
                            aliquam condimentum quam suspendisse. Sit bibendum adipiscing enim lacus quis. A nec accumsan
                            aliquam magnis orci. Dui amet scelerisque lectus commodo iaculis semper. Arcu eget vestibulum
                            amet faucibus eugi augue Odio viverra odio tempor porttitor orci, gravida orci consequat.
                            Aliquet aliquet nibh sit massa. Molestie proin facilisis ac vulputate sed quam et, arcu mattis.
                            In egestas rutrum pharetra sit dictum augue.</p>
                        <p>Imperdiet consectetur gravida vel rutrum. Tempus, mattis sit massa lacus morbi. Feugiat aliquam
                            aenean sagittis nunc neque sit. Nec lorem tincidunt arcu nunc ac accumsan risus felis in. Mi
                            ornare convallis tincidunt maecenas dui auctor cursus platea velit. Augue eget turpis facilisi
                            dui vel at faucibus diam. Semper non vitae elit mattis eu sodales. Sapien pellentesque sagittis
                            enim.</p>
                    </div>
                    <div className="experience-skills">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-6 col-sm-12 skills-column">
                                <div className="skills-inner mr_20">
                                    <div className="text-box">
                                        <h3>Volunteer Skills</h3>
                                        <p>Consectetur adipiscing elit. Semper sagittis dolor aliquet quam feugiat ultrices
                                            feugiat Viverra facilisi turpis.</p>
                                    </div>
                                    <div className="progress-inner">
                                        <div className="progress-box">
                                            <p>Donation</p>
                                            <div className="bar">
                                                <ProgressBar className="bar-inner count-bar" animated now={now} label={`${now}%`} />
                                            </div>
                                        </div>
                                        <div className="progress-box">
                                            <p>Medical Help</p>
                                            <div className="bar">
                                                <ProgressBar className="bar-inner count-bar" animated now={90} label={`${90}%`} />
                                            </div>
                                        </div>
                                        <div className="progress-box">
                                            <p>Poor Education</p>
                                            <div className="bar">
                                                <ProgressBar className="bar-inner count-bar" animated now={80} label={`${80}%`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 education-column">
                                <div className="education-inner">
                                    <div className="text-box">
                                        <h3>Education</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Libero</p>
                                    </div>
                                    <div className="inner-box">
                                        <div className="single-item">
                                            <h4>University of Iowa</h4>
                                            <p>Master's degree - Macroeconomics <br />2012 - 2013</p>
                                        </div>
                                        <div className="single-item">
                                            <h4>University of Chicago</h4>
                                            <p>Honours - Macroeconomics <br />2008 - 2011</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 experience-column">
                                <div className="experience-inner">
                                    <div className="text-box">
                                        <h3>Experience</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Libero</p>
                                    </div>
                                    <div className="inner-box">
                                        <div className="single-item">
                                            <h4>Jr. Graphic Designer</h4>
                                            <p><span>Arpin Van Lines</span> Jun 2017-Sep 2019</p>
                                        </div>
                                        <div className="single-item">
                                            <h4>Sr. UI/UX Designer</h4>
                                            <p><span>Berkery MandASoft</span> Jun 2019-Present</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Bio;
