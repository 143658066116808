import React from 'react';
import './css/consultency.css';

const Consultency = () => {
    const services = [
        {
            icon: './images/consultancy/career.png',
            title: 'Career',
            description: 'Astrology Career Guidance Content: Navigate Success.',
        },
        {
            icon: './images/consultancy/marriage.png',
            title: 'marriage',
            description: 'Expert insights on marriage through astrological guidance.',
        },
        {
            icon: './images/consultancy/worship.png',
            title: 'Worship lesson',
            description: 'Guidance on Spiritual Worship Practices for Astrologers.',
        },
        {
            icon: './images/consultancy/pregnancy.png',
            title: 'Pregnancy',
            description: 'Astrology Predictions for Pregnancy Outcomes.',
        },
        {
            icon: './images/consultancy/manglik.png',
            title: 'Manglik Dosha',
            description: 'Manglik Dosha: Astrological Aspect, Impact, and Remedies.',
        },
        {
            icon: './images/consultancy/kundali.png',
            title: 'Kundli Dosha',
            description: 'Understanding Kundli Dosha for Astrological Insights.',
        },
        {
            icon: './images/consultancy/Festivals.png',
            title: 'Festivals',
            description: 'Astrologer predicts auspicious times during festivals.',
        },
        {
            icon: './images/consultancy/Analysis.png',
            title: 'Analysis By Numerology',
            description: 'Numerology provides insightful personal analyses.',
        },
        // {
        //     icon: './images/consultancy/Analysis.png',
        //     title: 'Name Analysis',
        //     description: 'Astrologer provides insightful name analyses.',
        // },
    ];
    return (
        <div className="consultency_wrap  bgtheme">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="titleSec_wrapper">
                            <h2 className='heading'>Our area of <span>Consultancy</span></h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                            <p className='desc'>Explore expert consultancy services for astrological guidance and insights. Transform your life positively.</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="row">
                            {services.map((item, index) => (
                                <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <div className="consultency_box">
                                        <div className="consultency_box_icon">
                                            <img src={item.icon} alt="" />
                                            <div className="btc_step_overlay"></div>
                                        </div>
                                        <div className="consultency_box_cnt">
                                            <div className='consultency_Title'>{item.title}</div>
                                            <p className='consultency_desc'>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Consultency;