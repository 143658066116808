import React, { useEffect, useState } from "react";
import {Link, useParams} from 'react-router-dom';
import './css/blog-detail.css';
import Breadcrumb from '../components/breadcrumb';
import axios from "axios";
import Moment from "react-moment";
import api, { baseUrl } from "../api/api";
const BlogSection = () => {
    const {id} = useParams();
    const data ={ pageTitle : "Blog Detail" }
    const parse = require('html-react-parser');
    const [postData, setpostData] = useState({
        title: '',
        description: '',
        blogImage: '',
        created_at: '',    
});
    useEffect(() => {
        api
      .post('/getBlogById', {id: id})
      .then((resp) => {
        setpostData({
            ...postData,
            title: resp.data.recordList.title,
            description: resp.data.recordList.description,
            blogImage: resp.data.recordList.blogImage,
            created_at: resp.data.recordList.created_at,
        })
        console.log(postData)
      })
      .catch((error) => {
        console.log(error)
      })
    }, [])
    return (
        <div>
            <Breadcrumb {...data} />

            <section className="as_blog_wrapper pb-80 pt-30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"> </div>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">                            
                            <div className="as_blog_box">
                                <div className="as_blog_img">
                                    <img src={baseUrl + postData.blogImage} alt="img" className="img-fluid" />
                                    <span className="as_btn"><Moment format="MMMM DD, YYYY">{postData.created_at}</Moment></span>
                                </div>
                                <div className="as_blog_detail">
                                    <h4 className="as_subheading"> {postData.title}</h4>
                                    <p>{parse(postData.description)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"> </div>
                    </div>
                </div>
            </section>
        </div >
    );
};

export default BlogSection;
