import React from 'react';
import { Link } from 'react-router-dom';

const AboutSection = () => {
    return (
        <section className="about_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className='titleSec_wrapper'>
                            <h2 className="heading">About <span>AstroMaya</span></h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                            <p className='desc'>Astrology explores celestial influences on human affairs, guiding insights into personality, behavior, and life events.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="aboutimg text-right">
                            <img src="/images/about.jpg" alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="about_detail">
                            <div className="heading">What Do We Do ?</div>
                            <div className="paragraph_wrapper">
                            <p>Our astrology services offer insightful readings, personalized horoscopes, and guidance.</p>
                            <p>Expert astrologers analyze celestial influences to provide accurate predictions, helping you navigate life's challenges and make informed decisions.</p>
                            <p>Delve into the cosmos with our astrology expertise. Unlock celestial insights guiding your journey.</p>
                            </div>

                            <div className="contact_expert">
                                <span className="icon">
                                    <img src="/images/svg/about.svg" alt="" />
                                </span>
                                <span className="year_ex">30</span>
                                <div>
                                    <div className='years_of'>years of</div>
                                    <div className='experience'>Experience</div>
                                </div>
                            </div>
                            <div className='btn_wapper'>
                                <Link className="as_btn" to="/about">Read more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutSection;
