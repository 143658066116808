import React, {useState, useEffect} from 'react';
import Breadcrumb from '../components/breadcrumb';
import api, { baseUrl } from '../api/api';
import axios from 'axios';
const TermsAndCondition = () => {
    const data = {
        pageTitle: "Terms And Conditions"
    }
    const parse = require('html-react-parser');
    const [pdata, setData] = useState({
        body: '',
    });
    const fetchData = async () => {
        await api.get("/termsCondition")
        .then((resp) => {
            setData(resp.data.recordList[0]);
        })
    }
    useEffect(()=>{
        fetchData();
    }, [])
    return (
        <div className='vastuSec'>
            <Breadcrumb {...data} />

            <div className='container'>
                <div className='termsCondtions'>
                    <div className='terms-subsec'>
                        <h4>Terms & Conditions</h4>
                        {parse(pdata.body)}    
                     </div>   
                </div>
            </div>
        </div>

    )
}
export default TermsAndCondition;