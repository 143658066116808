import React, {useState, useEffect} from 'react';
import Breadcrumb from '../components/breadcrumb';
import api, { baseUrl } from '../api/api';
import axios from 'axios';
const PrivacyPolicy = () => {
    const [pdata, setData] = useState({
        body: '',
    });
    const parse = require('html-react-parser');
    const data = { pageTitle: "Privacy Policy" }
    const fetchData = async () => {
        await api.get("/privacyPolicy")
        .then((resp) => {
            setData(resp.data.recordList[0]);
        })
    }
    useEffect(()=>{
        fetchData();
    }, [])
    return (
        <div className='vastuSec'>
            <Breadcrumb {...data} />

            <div className='container'>

                <div className='privacy'>
                    <h4>Privacy Policy</h4>
                    {parse(pdata.body)}
                </div>
            </div>
        </div>

    )
}
export default PrivacyPolicy;
