import React from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import './css/kundali-making.css';
import Breadcrumb from '../components/breadcrumb';

const kundliMaking = () => {
    const data = {
        pageTitle: "Kundli Making"
    }
    return (
        <div className='kundliMaking'>
            <Breadcrumb {...data} />

            <div className='kundliMakingSec'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 text-center titleArea">
                            <h1 className="as_heading">Kundli Making</h1>
                            <h4 className="animatedLine"><span>&nbsp;</span></h4>
                            <p className="">Kundli making reveals cosmic influences on an individual's life. Analyzing planetary positions <br /> at birth, it guides personal, career, and relationship decisions with astrological insights.</p>
                        </div>
                        <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                            <img src="/images/blog_single1.jpg" alt="" className='img-fluid' />
                        </div>
                        <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
                            <Form className="form myCard">
                                <Container>
                                    <Row className="mb-3">
                                        <Col xxl={6} xl={6} lg={6} md={12} xs={12}>
                                            <Form.Group className=" mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="Name" name="name" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={12} xs={12}>
                                            <Form.Group className='makingGender'>
                                                <Form.Label className='d-block'>Gender</Form.Label>
                                                <Form.Check
                                                    inline name="gender" type="radio" aria-label="radio 1" label=" Male"
                                                />
                                                <Form.Check className='me-0'
                                                    inline name="gender" type="radio" aria-label="radio 2" label=" Female"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={4} xl={4} lg={4} xs={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Day</Form.Label>
                                                <Form.Control type="number" placeholder="Days" min="0" max="31" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={4} xl={4} lg={4} xs={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Month</Form.Label>
                                                <Form.Control type="number" placeholder="Month" min="0" max="12" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={4} xl={4} lg={4} xs={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Day</Form.Label>
                                                <Form.Control type="number" placeholder="Year" min="0" max="" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={4} xl={4} lg={4} xs={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Hour</Form.Label>
                                                <Form.Control type="number" placeholder="Houre" min="0" max="24" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={4} xl={4} lg={4} xs={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Minutes</Form.Label>
                                                <Form.Control type="number" placeholder="Minutes" min="0" max="60" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={4} xl={4} lg={4} xs={4}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Seconds</Form.Label>
                                                <Form.Control type="number" placeholder="Seconds" min="0" max="60" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} xs={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Place of Birth</Form.Label>
                                                <Form.Control type="text" placeholder="Place of Birth" />
                                            </Form.Group>
                                        </Col>
                                        <Col xxl={12} xl={12} lg={12} xs={12} className='text-center'>
                                            <Button className="as_btn" type="submit">
                                                Create Kundli
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default kundliMaking   