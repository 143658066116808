import React from 'react';

import AboutSection from './about-us-sec';
import AuthorBio from './author-bio';
import FaqSec from './faq';
import Breadcrumb from '../components/breadcrumb';

const About = () => {
    const data = {
        pageTitle : "About us"
    }
    return (
        <>
            <Breadcrumb {...data} />
            <AboutSection />
            <AuthorBio />
            <FaqSec />
        </>
    )
}
export default About;