import React from 'react';
import { Link } from 'react-router-dom';
import './css/index.css';
import Breadcrumb from '../components/breadcrumb';

const Services = () => {
    const data ={
        pageTitle : "Premium Srevice"
    }
    const topCategories = [
        'Premium Service',
        'Call to Astrologer',
        'Chat to Astrologer',
        'kundali Matching',
        'Kundali Making'
    ];
    const Archives = [
        'January 2022',
        'February 2022',
        'March 2022',
        'April 2022',
        'May 2022',
        'June 2022',
    ]
    const WorkingHours = [
        {
            weekName: 'Monday',
            time: '09:00 - 12:00'
        },
        {
            weekName: 'Tuesday',
            time: '09:00 - 12:00'
        },
        {
            weekName: 'Wednesday',
            time: '09:00 - 12:00'
        },
        {
            weekName: 'Thursday',
            time: '09:00 - 12:00'
        },
        {
            weekName: 'Friday',
            time: '09:00 - 12:00'
        },
        {
            weekName: 'Saturday',
            time: '09:00 - 12:00'
        },
        {
            weekName: 'Sunday',
            time: '09:00 - 12:00'
        }
    ]
    const Celebrities = [
        {
            id : '1',
            img : "/images/kundali/comm_img1.jpg",
            name: 'Jhon Doe',
            date: '25 August, 2022 ',
            content: 'Integer porttitor fringilla vestibulum. Phasellus curs our tinnt nulla, ut mattis augue finibus ac. Vivamus elementum enim ac enim ultrices rhoncus. '
        },
        {
            id : '2',
            img : "/images/kundali/comm_img2.jpg",
            name: 'Jhon Doe',
            date: '25 August, 2022 ',
            content: 'Integer porttitor fringilla vestibulum. Phasellus curs our tinnt nulla, ut mattis augue finibus ac. Vivamus elementum enim ac enim ultrices rhoncus. '
        },
        {
            id : '3',
            img : "/images/kundali/comm_img3.jpg",
            name: 'Jhon Doe',
            date: '25 August, 2022 ',
            content: 'Integer porttitor fringilla vestibulum. Phasellus curs our tinnt nulla, ut mattis augue finibus ac. Vivamus elementum enim ac enim ultrices rhoncus. '
        }
    ]
    return (
        <div>
            <Breadcrumb {...data} />
            
            <div className="kd_sidebar_main_wrapper num_sidebar_main_wrapper pt-50 bgtheme">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-25">
                            <div className="sign_left_tabs_wrapper sign_left_tabs_border_wrapper1">
                                <div className="slider_tabs_icon_wrapper">
                                    <i className="flaticon-aries-sign"></i>
                                </div>
                                <div className="slider_tabs_icon_cont_wrapper ar_tabs_heading_wrapper">
                                    <ul>
                                        <li><Link to="#" className="tabs_btn">Aries</Link></li>
                                        <li>31 March - 12 October</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9 col-md-9 col-sm-12 col-12">
                            <div className="kd_left_sidebar_main_wrapper">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="ar_first_sec_wrapper">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                                    <div className="ar_first_sec_img_wrapper mt-0">
                                                        <img src="/images/kundali/aries_img.jpg" alt="aries_img" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                                    <div className="ar_first_sec_img_cont_wrapper">
                                                        <p>The Ox occupies the second position in the Chinese Zodiac. The zodiac mls are, in order: Rat, Ox, Tiger, Rabbit, Dragon, Snake, Horse, Goat, Monkey, oser, Dog, and Pig. Each year is related to a Chinese
                                                            zodiac animal according to the 12-year-cycle.
                                                            {/* <br><br> */}
                                                            Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat sum, nec sagittis sem nibh id elit. Duis sed odio sit. The Ox occupies the cond
                                                            position in the Chinese Zodiac. The 12 zodiac animals are, in order: Rat, Tiger, Rabbit, Dragon, Snake, Horse, Goat, Monkey, Rooster, Dog, and Pig. Each year is related to a Chinesezodiac animal.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div className="ar_second_sec_cont_wrapper">
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                                                            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint aecat cupidatat non proident, sunt in culpa qui officia
                                                            deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div className="gnBox">
                                                        <ul>
                                                            <li>
                                                                <div className="pr_icon_wrapper">
                                                                    <i className="fa fa-circle"></i>
                                                                </div>
                                                                <div className=" icon_cont">
                                                                    <p><span>Lucky Colors</span> : white, yellow, green</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="pr_icon_wrapper">
                                                                    <i className="fa fa-circle"></i>
                                                                </div>
                                                                <div className=" icon_cont">
                                                                    <p><span>Lucky Numbers</span> : 1, 4</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="pr_icon_wrapper">
                                                                    <i className="fa fa-circle"></i>
                                                                </div>
                                                                <div className=" icon_cont">
                                                                    <p><span>Lucky Flowers</span> : tulip, morning glory, peach blossom</p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="pr_icon_wrapper">
                                                                    <i className="fa fa-circle"></i>
                                                                </div>
                                                                <div className=" icon_cont">
                                                                    <p><span> Year of Birth </span>: 1913, 1925, 1937, 1949, 1961, 1973, 1985, 1997, 2009, 2021</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="kd_first_sec_wrapper ar_third_sec_heading_wrapper">
                                                <h2>Aries Celebrities</h2>
                                                <h4><span>&nbsp;</span></h4>
                                            </div>
                                        </div>

                                        {Celebrities.map((item,index) => (
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="comments_Box first_comment_box" key={index}>
                                                    <div className="img_wrapper">
                                                        <img src={item.img}  alt="author_img" />
                                                    </div>
                                                    <div className="text_wrapper">
                                                        <div className="author_detail">
                                                            <span className="author_name">{item.name} - </span>
                                                            <span className="publish_date">{item.date} </span>
                                                        </div>
                                                        <div className="author_content">
                                                            <p>{item.content} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                            <div className="service_sidebar">
                                <div className="as_widget as_category_widget">
                                    <h3 className="as_widget_title mb-3">Top Categories</h3>
                                    <ul>
                                        {topCategories.map((category, index) => (
                                            <li key={index}>
                                                <Link to="#!">{category}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="as_widget as_archives_widget">
                                    <h3 className="as_widget_title mb-3">Archives</h3>
                                    <ul>
                                        {Archives.map((item, index) => (
                                            <li key={index}>
                                                <Link to="#!">{item}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="as_widget as_workinghours_widget">
                                    <h3 className="as_widget_title mb-3">Working Hours</h3>
                                    <ul>
                                        {WorkingHours.map((item, index) => (
                                            <li key={index}>
                                                <Link to="#!"><span>{item.weekName}</span> <span>{item.time}</span></Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Services;
