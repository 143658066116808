import React from 'react';
// import { Link } from 'react-router-dom';
import Breadcrumb from '../components/breadcrumb';

const Numerology = () => {
    const data = {
        pageTitle: "Numerology"
    }
    return (
        <div className='numerologyPage'>
            <Breadcrumb {...data} />

            <div className='srvPage_wrap'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="serPage_img">
                                <img src="/images/numerology.jpg" alt="numerology" className="rounded-2 img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className='title'>What is a life path number</div>
                            <p className='mb-3'>what is a life path number It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
                            <p className='mb-3'>Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.The point of using Lorem Ipsum is that it has a more-or less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English will uncover many web sites still in their infancy.</p>
                            <p>"A Life Path Number in numerology represents your unique journey and traits. Calculated from your birthdate, it offers insights into life's purpose and challenges."</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='numeSec bgtheme'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 text-center pb-30">
                            <h1 className="as_heading">How-To: Calculate the Life Path Number</h1>
                            <h4 className="animatedLine"><span>&nbsp;</span></h4>
                            <p className=" pb-20">"To calculate your Life Path Number in numerology, add the digits of your birthdate until you get a single-digit result. Explore its significance and meaning."</p>
                        </div>
                        <div className='col-12'>
                            <div className='numeSrv'>
                                <div className="nume_title">1.Begin with your full birth date.</div>
                                <p>Example : December 19, 2017</p>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='numeSrv'>
                                <div className="nume_title">2. Reduce the month, day and year down to a single digit by adding them.</div>
                                <div className='mb-2'>
                                    <div className='nume_subtitle'>Day -</div>
                                    <ul className='ps-2'>
                                        <li>The day of birth is 19</li>
                                        <li>Add 1 + 9 = 10</li>
                                        <li>Keep adding to digit down to a one-digit number:1 + 0 = 1</li>
                                    </ul>
                                </div>
                                <div className='mb-2'>
                                    <div className='nume_subtitle'>Month -</div>
                                    <ul className='ps-2'>
                                        <li>December is the 12th month</li>
                                        <li>Add 1 + 2 = 3</li>
                                    </ul>
                                </div>
                                <div>
                                    <div className='nume_subtitle'>Year -</div>
                                    <ul className='ps-2'>
                                        <li>The year of birth is 2017</li>
                                        <li>Add all four numbers together: 2 + 0 + 1+ 7 = 10.</li>
                                        <li>Continue adding until you get a one-digit number: 1 + 1 = 2</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='numeSrv'>
                                <div className="nume_title">3. Now add the resulting single digits together to get the Life Path Number.</div>
                                <div className='mb-2'>
                                <ul className='ps-2 lul'>
                                        <li>Month=3 + Day=1 + Year=2</li>
                                        <li>3 + 1 + 2 = 6</li>
                                    </ul>
                                </div>
                                <div className='nume_subtitle2'>The Life Path Number is 12</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Numerology;
