import React, { useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tab, Nav, Modal } from 'react-bootstrap';
import './css/mall-details.css';

import Breadcrumb from '../components/breadcrumb';
import api, { baseUrl } from '../api/api';
import axios from 'axios';
const MallDetails = () => {
    const {id} = useParams();
    const [visible, setVisible] = useState('');
    const data ={ pageTitle : "Product Detail" }
    const [productDetail, setProductDetail] = useState([]);
    const fetchProduct = async () => {
        await api.post('/getAstromallProductById', {id: id})
        .then((resp) => {
            setProductDetail(resp.data.recordList[0]);
            console.log(productDetail)
        })
    }
    useEffect(()=>{
        fetchProduct();
    }, [])

    return (
        <>
        <div className='mall'>
            <Breadcrumb {...data} />

            <section className="mallDetails_Wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <Tab.Container id="vertical-tabs" defaultActiveKey="key1" className="cTabs">
                                <div className="product_slider">
                                    <div className="prd_slider_thumb">
                                        <Nav variant="pills" className="flex-column ul">
                                            <Nav.Item className='li'>
                                                <Nav.Link eventKey="key1">
                                                    <img src={baseUrl + productDetail.productImage} alt="product" />
                                                </Nav.Link>
                                                <Nav.Link eventKey="key2">
                                                    <img src="/images/prod2.jpg" alt="product" />
                                                </Nav.Link>
                                                <Nav.Link eventKey="key3">
                                                    <img src="/images/prod3.jpg" alt="product" />
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <div className="prd_slider_img">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="key1">
                                                <div className="img-item">
                                                    <Link to="#" data-id="1">
                                                        <img src={baseUrl + productDetail.productImage} alt="product" />
                                                    </Link>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="key2">
                                                <div className="img-item">
                                                    <Link to="#" data-id="2">
                                                        <img src="/images/prod2.jpg" alt="product" />
                                                    </Link>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="key3">
                                                <div className="img-item">
                                                    <Link to="#" data-id="3">
                                                        <img src="/images/prod3.jpg" alt="product" />
                                                    </Link>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="prdDetail">
                                <div className="prdName mb-2">{productDetail.name}</div>
                                <div className="prdPrice mb-3">₹ {productDetail.amount}/-</div>

                                <div className='prdtitle mb-1'>Features:</div>
                                <p>{productDetail.features}</p>
                                <p className="as_padderBottom10">
                                    {productDetail.description}
                                </p>
                                <div className="prdBtn">
                                    <Link onClick={() => setVisible(true)} type="button" className="buy_btn as_btn" value="Buy Now">
                                        Buy Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </div >
        {visible && (           
            <Modal show={visible} onClick={() => setVisible(false)}  centered size="lg">
              <Modal.Body>
              <div className='appdwnBox text-center'>
                <h4 className='pb-4 text-white'>Plaese Download Our Astrology App</h4>
                <div className=''>
                    <Link to="#" className='me-3'><img src="/images/App-Store.png" className="img-fluid" alt="App Store" /></Link>
                    <Link to="#"><img src="/images/Google-Play.png" className="img-fluid" alt="Google Play" /></Link>
                </div>
              </div>
              </Modal.Body>
            </Modal>
          )}
    </>      
    );
}

export default MallDetails;
