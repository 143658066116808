import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/breadcrumb';
import '../home/css/blog.css';
import Moment from "react-moment";
import api, { baseUrl } from '../api/api';
import axios from 'axios';
const Blog = () => {
    const parse = require('html-react-parser');
    const [blog, setBlog] = useState([]);
    const [fetchRecord] = useState(3);
    const [startIndex] = useState(0);
    const fetchBlog = async () => {
        await api.post("/activeBlogs",
            {
                startIndex: startIndex,
                // fetchRecord: fetchRecord
            })
        .then((resp) => {
            setBlog(resp.data.recordList);
        })
    }
    useEffect(()=>{
        fetchBlog();
    }, [])
    const data = {
        pageTitle: "Blog"
    }

    return (
        <div className='blogPage'>
            <Breadcrumb {...data} />

            <div className="blog_wrapper bgtheme">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="titleSec_wrapper">
                                <h2 className='heading'>Latest <span> Blog</span></h2>
                                <h4 className='animatedLine'><span>&nbsp;</span></h4>
                                <p className='desc'>Explore celestial insights and cosmic wisdom with our astrologer's captivating blog content. <br />Discover guidance and enlightenment.</p>
                            </div>
                        </div>
                        {blog.map((news, index) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                                <div className="blog_box">
                                    <div className="blog_box_img">
                                        <img src={baseUrl+news.blogImage} alt="blog_img" />
                                    </div>
                                    <div className="blog_box_cont">
                                        <div className='date'><Moment format="DD MMM, YYYY">{news.created_at}</Moment></div>
                                        <div className='blogTitle'>{news.title}</div>
                                        <p className='desc'>{parse(news.description.slice(0, 200))}</p>
                                        <Link to={`/blog-detail/${news.id}`}>Read More <i className="fa fa-long-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;
