import React from 'react';
import { Link } from 'react-router-dom';
import './css/blog.css';
import Moment from "react-moment";
import api, { baseUrl } from '../api/api';

const HomeBlog = ({data}) => {
    const parse = require('html-react-parser');
    
    return (
        <div className="blog_wrapper bgtheme">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="titleSec_wrapper">
                            <h2 className='heading'>Latest <span> Blog</span></h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                            <p className='desc'>Explore celestial insights and cosmic wisdom with our astrologer's captivating blog content. <br />Discover guidance and enlightenment.</p>
                        </div>
                    </div>
                    {data.map((news, index) => (
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <div className="blog_box">
                                <div className="blog_box_img">
                                    <img src={baseUrl+news.blogImage} alt="blog_img" />                                    
                                </div>
                                <div className="blog_box_cont">
                                    <div className='date'><Moment format="DD MMM, YYYY">{news.created_at}</Moment></div>
                                    <div className='blogTitle'>{news.title}</div>
                                    <p className='desc'>{parse(news.description.slice(0, 200))}</p>
                                    <Link to={`/blog-detail/${news.id}`}>Read More <i className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='btn_wapper mt-3 text-center'>
                            <Link className="as_btn" to="/blog">View All</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeBlog;
