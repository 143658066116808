import React from 'react';
import './css/faq.css';

import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

const FaqSec = () => {
    const faqs = [
        {
            id: 'faqSec1',
            question: 'Q1: What is astrology?',
            answer: 'Astrology links celestial bodies to events and traits on Earth, offering insights into character, behavior, and life events.'
        },
        {
            id: 'faqSec2',
            question: 'Q2: What is a horoscope?',
            answer: 'A horoscope is a personalized birth chart showing celestial positions, offering insights into personality, strengths, weaknesses, and potential events.'
        },
        {
            id: 'faqSec3',
            question: 'Q3: What are zodiac signs?',
            answer: 'Zodiac signs, like Aries, Taurus, etc., are determined by birth dates and represent 12 periods in the year, each with unique traits.'
        },
        {
            id: 'faqSec4',
            question: 'Q4: Can astrology predict the future',
            answer: 'Astrology lacks scientific validity for predicting precise events, but it can provide insights into potential trends and challenges based on celestial positions.'
        },
        {
            id: 'faqSec5',
            question: 'Q4: What is a horoscope reading',
            answer: 'A horoscope reading involves interpreting a persons birth chart to offer insights into their personality, life events, and potential future trends.'
        }
    ];
    return (
        <div className="faq_wrap bgtheme">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <div className="titleSec_wrapper mb-4">
                            <h2 className='heading'>Faq</h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="faq_wrap_img">
                            <img src="/images/about/pgress_img.jpg" className="img-fluid b_r-30" alt="prog_img" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="faq_que">
                            <MDBAccordion initialActive='faqSec2'>
                                {faqs.map((item, index) => (
                                    <MDBAccordionItem collapseId={item.id} headerTitle={item.question} key={index}>
                                        {item.answer}
                                    </MDBAccordionItem>
                                ))}
                            </MDBAccordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqSec;
