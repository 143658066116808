import React from 'react';
import { Link } from 'react-router-dom';
import './css/banner.css';

import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay} from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/autoplay';


const BannerSlider = () => {
    const bannerDetails = [
        {
            id: 1,
            title: 'Trust our experience',
            subtitle: 'Start control of your professional destiny',
            description:
                'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.',
            buttonLabel: 'Appointment',
        },
        {
            id: 2,
            title: "What's Your Sign ?",
            subtitle: 'Read Your Daily Horoscope Today',
            description:
                'Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.',
            buttonLabel: 'Appointment',
        },
        {
            id: 3,
            title: "What's Your Sign ?",
            subtitle: 'Read Your Daily Horoscope Today',
            description:
                'Consectetur adipiscing elit, sed do eiusmod tempor incididuesdeentiut labore etesde dolore magna aliquapspendisse and the gravida.',
            buttonLabel: 'Appointment',
        },
    ];

    return (
        <section className="banner_wrapper">
            <div className="container">
                <div className="row verticle_center">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="banner_slider">
                        <Swiper                                
                                modules={[Navigation, Pagination, Autoplay]}
                                autoplay={{
                                    delay: 30000000, 
                                    disableOnInteraction: false, 
                                  }}
                                loop={true}
                                navigation={true}
                                pagination={false}
                                // breakpoints={{
                                //     576: {
                                //         //navigation:false,
                                //         //pagination:true 
                                //     }
                                // }}
                            >
                            {bannerDetails.map((banner) => (
                                <SwiperSlide key={banner.id}>
                                    <div className="banner_detail" key={banner.id}>
                                        <div className='title'>{banner.title}</div>
                                        <div className='subtitle'>{banner.subtitle}</div>
                                        <p>{banner.description}</p>
                                        <Link to="#!" className="as_btn">
                                            {banner.buttonLabel}
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="banner_img text-center">
                            <img src="/images/hand_bg1.png" alt="" className="img-fluid hand_bg" />
                            {/* <img src="/images/vastu.png" alt="" className="img-fluid hand" /> */}
                            <img src="/images/vastu1.1.png" alt="" className="img-fluid hand" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerSlider;
