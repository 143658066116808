import React from 'react';
import './css/testimonial.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/autoplay';

const testimonialData = [
    {
        id: 1,
        name: 'Ralph Rios',
        role: 'Creative Director',
        imagesrc: "/images/testi_client_img1.jpg",
        content:'AstroMaya has illuminated my life journey. Their precise predictions and insightful guidance have been invaluable. The personalized approach and deep knowledge make them my trusted astrological companion. Highly recommended!',
    },
    {
        id: 2,
        name: 'Ralph Rios',
        role: 'Creative Director',
        imagesrc: "/images/testi_client_img2.jpg",
        content:'AstroMaya has illuminated my life journey. Their precise predictions and insightful guidance have been invaluable. The personalized approach and deep knowledge make them my trusted astrological companion. Highly recommended!',
    },
    {
        id: 3,
        name: 'Ralph Rios',
        role: 'Creative Director',
        imagesrc: "/images/testi_client_img1.jpg",
        content:'AstroMaya has illuminated my life journey. Their precise predictions and insightful guidance have been invaluable. The personalized approach and deep knowledge make them my trusted astrological companion. Highly recommended!',
    },
    {
        id: 4,
        name: 'Ralph Rios',
        role: 'Creative Director',
        imagesrc: "/images/testi_client_img2.jpg",
        content:'AstroMaya has illuminated my life journey. Their precise predictions and insightful guidance have been invaluable. The personalized approach and deep knowledge make them my trusted astrological companion. Highly recommended!',
    }
];

const TestimonialSlider = () => {
    return (
        <div className="testimonial_wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="titleSec_wrapper">
                            <h2 className='heading'> What clients <span> are saying</span> </h2>
                            <h4 className='animatedLine'> <span>&nbsp;</span> </h4>
                            <p className='desc'> Clients praise the astrologer's insightful guidance, accurate predictions, <br />and compassionate approach to astrology.</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="testimonial_slider">
                            <Swiper
                                modules={[Pagination, Autoplay]}
                                autoplay={{
                                    delay: 30000000,
                                    disableOnInteraction: false,
                                }}
                                loop={true}
                                spaceBetween={30}
                                slidesPerView={2}
                                pagination={{ clickable: true }}
                                breakpoints={{
                                    576: {
                                        slidesPerView:2
                                    },
                                    290: {
                                        slidesPerView:1
                                    }
                                }}
                            >
                                {testimonialData.map((testimonial) => (
                                    <SwiperSlide key={testimonial.id}>
                                        <div className="item">
                                            <div className="testimonial_slider_content">
                                                <img
                                                    src={testimonial.imagesrc} className="img-fluid"
                                                    alt="testimonial"
                                                />
                                                <div className='tstCont'>
                                                    <div className='testName'>{testimonial.name}</div>
                                                    <small>{testimonial.role}</small>
                                                    <p>{testimonial.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialSlider;
