import React from 'react';
// import { Link } from 'react-router-dom';
import Breadcrumb from '../components/breadcrumb';
import './css/common.css';

const EnergyHealing = () => {
    const data = {
        pageTitle: "Energy Healing"
    }
    return (
        <div className='plamPage'>
            <Breadcrumb {...data} />
            <div className='srvPage_wrap'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="serPage_img">
                                <img src="/images/palm.jpg" alt="palm" className="img-fluid rounded-2" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className='title'>About Palm Reading</div>
                            <p className='mb-3'>Palm reading, also known as chiromancy, is an ancient art of interpreting the lines and mounts on one's palm. Unlock the secrets of your destiny as skilled practitioners analyze your palm's unique features, providing insights into your personality, relationships, and life's journey. Discover the mystique of palmistry.</p>
                            <p className='mb-3'>Also known as chiromancy, is an ancient practice of interpreting the lines, shapes, and mounts on the palm of the hand to gain insights into an individual's personality, behavior, and potential future events. This form of divination is found in various cultures across the world and has been practiced for centuries.</p>
                            <p>Delve into the ancient art of palmistry with our detailed guide. Uncover insights into personality, relationships, and destiny through the lines and mounts on your hands. Discover the fascinating world of palm reading.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='plamSec bgtheme'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 text-center pb-50">
                            <h1 className="as_heading">Major Keys:</h1>
                            <h4 className="animatedLine"><span>&nbsp;</span></h4>
                            <p className=" pb-20">Unique patterns that some palmists consider significant.</p>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className='plamSrv'>
                                <div className="plam_title">Major Lines:</div>
                                <p><b>Heart Line:</b> Represents emotions, relationships, and the state of one's heart.</p>
                                <p><b>Head Line:</b> Reflects intellect, thought processes, and decision-making.</p>
                                <p><b>Life Line:</b> Indicates the general course of one's life and overall vitality.</p>
                                <p><b>Fate Line:</b> Associated with destiny, career, and life path.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className='plamSrv'>
                                <div className="plam_title">Minor Lines:</div>
                                <p><b>Sun Line:</b> Represents emotions, relationships, and the state of one's heart.</p>
                                <p><b>Mercury Line:</b> Relates to communication, business acumen, and health.</p>
                                <p><b>Marriage Lines: </b> Indicate relationships, unions, and potential partnerships.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className='plamSrv'>
                                <div className="plam_title">Mounts:</div>
                                <p><b>Mount of Venus: </b> Relates to love, passion, and vitality.</p>
                                <p><b>Mount of Jupiter: </b> Linked to ambition, leadership, and authority.</p>
                                <p><b>Mount of Saturn: </b> Associated with discipline, responsibility, and wisdom.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className='plamSrv'>
                                <div className="plam_title">Shapes and Markings:</div>
                                <p><b>Fingerprints:</b> Unique patterns that some palmists consider significant.</p>
                                <p><b>Moons: </b> Circular markings that may suggest sensitivity or intuition.</p>
                                <p><b>Crosses: </b> Intersection of lines that may indicate challenges or changes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EnergyHealing;
