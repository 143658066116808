import React from 'react';
// import { Link } from 'react-router-dom';
import './index.css';
import Breadcrumb from '../components/breadcrumb';

const Vastu = () => {
    const data = {
        pageTitle: "Vastu"
    }
    const process = [
        {
            id: '1',
            img: "/images/vastu/proposal.png",
            title: 'Proposal',
            desc: 'The Vastu process begins with a proposal for the site. Through an initial phone call or email exchange, we gather details about your space to comprehend your needs. This aids in providing a tailored consultation and determining applicable fees.',
        },
        {
            id: '2',
            img: "/images/vastu/floor_plan.png",
            title: 'Floor Plan & Details',
            desc: 'At stage two, a detailed floor plan with precise directional alignment, site surroundings, premises history, and your goals is essential. This comprehensive approach ensures a holistic understanding, laying the foundation for effective planning and harmonious space utilization.',
        },
        {
            id: '3',
            img: "/images/vastu/site_survey.png",
            title: 'Site Survey, Google mapping',
            desc: 'We conduct on-site evaluations and compass readings for your premises at a mutually agreed time and day. Typically lasting 1-2 hours, these assessments ensure precise insights for personalized Vastu recommendations tailored to enhance your space and well-being.',
        },
        {
            id: '4',
            img: "/images/vastu/reading.png",
            title: 'Vastu reading & evaluation',
            desc: 'Utilizing dowsing charts, bar charts, graphs, and grading tables, our comprehensive methods and tools assess spaces according to Vastu principles. We have developed precise techniques to enhance energy flow and create harmonious environments for optimal well-being and prosperity.',
        },
        {
            id: '5',
            img: "/images/vastu/report.png",
            title: 'Report by Vastu Consultant',
            desc: 'Receive a comprehensive Vastu report crafted by our expert consultant. Online consultations yield results in 3 days, while personal consultations take 7 days. Trust our qualified team for valuable insights into harmonizing your space.',
        },
        {
            id: '6',
            img: "/images/vastu/follow-up.png",
            title: 'Vastu Follow up',
            desc: 'Experience ongoing support with our follow-up option, assessing the effectiveness of Vastu implementation. Enjoy complimentary backup services for one year, ensuring sustained positive energy in the same premises long after the initial consultation.',
        }
    ]
    const services = [
        {
            id: '1',
            img: "/images/vastu/house.png",
            title: 'Home Vastu Consultation',
            desc: 'Home Vastu provides universal guidelines for homes, enhancing comfort and promoting positive energy flow. Elevate your living experience with personalized consultations, fostering lasting well-being and harmonious environments.',
        },
        {
            id: '2',
            img: "/images/vastu/office.png",
            title: 'Office Vastu Consultation',
            desc: 'Companies witness spontaneous prosperity boost by embracing Vastu-friendly locations, aligning energies for success, and fostering positive environments that contribute to overall well-being and growth.',
        },
        {
            id: '3',
            img: "/images/vastu/industrial.png",
            title: 'Industrial Vastu Consultation',
            desc: 'Optimize your industrial space with Vastu consultation. Align vibrations with core business functions, fostering a harmonious, prosperous work environment for enhanced productivity and success.',
        },
        {
            id: '4',
            img: "/images/vastu/consultency.png",
            title: 'Quick Vastu Consultation',
            desc: 'Optimize industrial spaces with Vastu consultations, enhancing vibrations for optimal business functions. Tailored solutions for harmonized energy flow, fostering success and productivity in your industrial setting.',
        }
    ]
    return (
        <div className='vastuSec'>
            <Breadcrumb {...data} />
            <div className='vastu_wrapper'>
                <div className='about_vastu section-bg'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-12 col-12">
                                <div className="vastu_img">
                                    <img src="images/vastu/vastu_service.jpg" alt="vastu" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-12 col-12">
                                <div className='vastu_cnt'>
                                <div className="vastuTitle text-purple mb-3">What is Vastu ?</div>
                                    <p className='mb-2'>Vaastu By AstroMaya is one of the destinations in the Vastu industry, offering valuable Vastu services to clients. She connects with people on a personal level and on a philosophical plane. </p>
                                    <p className='mb-2'>The services are available in the field of Vastu Consultancy. She says with conviction that all problems have solutions, all questions have answers, and all illnesses have remedies. </p>
                                    <p className='mb-2'>We aim to establish ourselves as a hub whose name is going to be known far and wide for its reliable Vastu services and high-quality spiritual experiences.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='vastu_tips bgtheme'>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-lg-12 col-md-12 text-center pb-50">
                                <h1 className="as_heading">Vastu Consultation Process</h1>
                                <h4 className="animatedLine"><span>&nbsp;</span></h4>
                                <p className="">Embark on Our Vastu Consultation Journey: A Holistic Approach to Aligning Spaces for Harmony, Balance, and Positive Transformations.</p>
                            </div>

                            {process.map((item, index) => (
                                <div className='col-lg-4 col-md-4 col-sm-6 col-12 mb-3'>
                                    <div className="vastuTips">
                                        <div className='icon'>
                                            <img src={item.img} alt="vastu" className="img-fluid" />
                                        </div>
                                        <div className="vastuTips__content">
                                            <div className="vastuTips__title">{item.title}</div>
                                            <p className="vastuTips__desc">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
                <section className="vastuServices section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="titleSec_wrapper mb-4">
                                    <h2 className='heading'>Our <span> Vastu</span> Services</h2>
                                    <h4 className='animatedLine'><span>&nbsp;</span></h4>
                                    <p className='desc'>Explore celestial insights and cosmic wisdom with our astrologer's captivating blog content. <br />Discover guidance and enlightenment.</p>
                                </div>
                            </div>
                            {services.map((item, index) => (
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div className="box">
                                        <div className="icon">
                                            <img src={item.img} alt="vastu services" />
                                        </div>

                                        <div className="title">{item.title} </div>

                                        <p className="description">{item.desc} </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Vastu;