import React from 'react';
import { Link } from 'react-router-dom';

const AboutVastu = () => {
    return (
        <section className="about_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className='titleSec_wrapper'>
                            <h2 className="heading">About <span>Vastu</span></h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                            <p className='desc'>Explore the ancient wisdom of Vastu Shastra, guiding the alignment of spaces to foster harmony, <br /> balance, and positive energies. Elevate well-being.</p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="aboutimg text-right">
                            <img src="/images/vastu/vastu_service.jpg" alt="vastu" width="100%" height="100%" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12">
                        <div className="about_detail ps-2">
                            <div className="heading text-purple">What is Vastu ?</div>
                            <div className="paragraph_wrapper border-0 ps-0">
                                <p className='mb-2'>Vaastu By AstroMaya is one of the destinations in the Vastu industry, offering valuable Vastu services to clients. She connects with people on a personal level and on a philosophical plane. </p>
                                <p className='mb-2'>The services are available in the field of Vastu Consultancy. She says with conviction that all problems have solutions, all questions have answers, and all illnesses have remedies. </p>
                                <p className='mb-2'>We aim to establish ourselves as a hub whose name is going to be known far and wide for its reliable Vastu services and high-quality spiritual experiences.</p>
                            </div>                            
                            <div className='btn_wapper mt-3'>
                                <Link className="as_btn" to="/vastu">Read more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutVastu;
