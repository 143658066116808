import React from 'react';
import { Link } from 'react-router-dom';
import './css/vastu-services.css';
const VastuService = () => {
    const services = [
        {
            id: '1',
            img: "/images/vastu/house.png",
            title: 'Home Vastu Consultation',
            desc: 'Home Vastu provides universal guidelines for homes, enhancing comfort and promoting positive energy flow. Elevate your living experience with personalized consultations, fostering lasting well-being and harmonious environments.',
        },
        {
            id: '2',
            img: "/images/vastu/office.png",
            title: 'Office Vastu Consultation',
            desc: 'Companies witness spontaneous prosperity boost by embracing Vastu-friendly locations, aligning energies for success, and fostering positive environments that contribute to overall well-being and growth.',
        },
        {
            id: '3',
            img: "/images/vastu/industrial.png",
            title: 'Industrial Vastu Consultation',
            desc: 'Optimize your industrial space with Vastu consultation. Align vibrations with core business functions, fostering a harmonious, prosperous work environment for enhanced productivity and success.',
        },
        {
            id: '4',
            img: "/images/vastu/consultency.png",
            title: 'Quick Vastu Consultation',
            desc: 'Optimize industrial spaces with Vastu consultations, enhancing vibrations for optimal business functions. Tailored solutions for harmonized energy flow, fostering success and productivity in your industrial setting.',
        }
    ]

    return (
        <section className="vastuServices section-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="titleSec_wrapper mb-4">
                            <h2 className='heading'>Our <span> Vastu</span> Services</h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                            <p className='desc'>Explore celestial insights and cosmic wisdom with our astrologer's captivating blog content. <br />Discover guidance and enlightenment.</p>
                        </div>
                    </div>
                    {services.map((item, index) => (
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="box">
                                <div className="icon">
                                    <img src={item.img} alt="vastu services" />
                                </div>

                                <div className="title">{item.title} </div>

                                <p className="description">{item.desc} </p>
                            </div>
                        </div>
                    ))}

                    <div className='btn_wrapper mt-2 text-center'>
                        <Link className="as_btn" to="/vastu">View More</Link>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default VastuService;
