import React from 'react';
import './astrologer-profile.css';

const AstrologerProfile = () => {
    return (
        <div className="astrologer_profile">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="titleSec_wrapper">
                            <h2 className='heading'><span> Astrologer</span> Profile</h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                            <p className='desc'>Explore astrologer's profile for expertise, insights, and personalized consultations. Trustworthy guidance for <br /> navigating life's complexities with confidence and clarity.</p>
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                        <div className="astrologer_slider">
                            <div className='chatAstro mt-4 mb-4'>
                                <div className='memberPic'>
                                    <img src="/images/c1.jpg" alt="member" />
                                    <center>
                                        <button className='btn follow-btn'>Follow</button>
                                    </center>
                                </div>
                                <div className='profileSec'>
                                    <div className='name mb-2'>David Parker</div>
                                    <div className='pb-2'>
                                        <div className='role'>
                                            <i className="fa fa-star pe-1"></i>
                                            Vedic, Numerology, Vedic, Numerology, Vedic, Numerology
                                        </div>
                                    </div>
                                    <div className='pb-2'>
                                        <div className='experience pb-2'>
                                            <i className="fa fa-clock-o pe-1"></i>
                                            4+ Years
                                        </div>
                                        <div className='language pb-2'>
                                            <i className="fa fa-language pe-1"></i>
                                            English, Hindi
                                        </div>
                                    </div>
                                    <div className='areaSec'>
                                        <div className='cnsultingCharge'>
                                            <h6 className='mb-2'>Consulting Charge</h6>
                                            <div className='charges'>
                                                <i className="fa fa-inr pe-1"></i>
                                                16/Min
                                            </div>
                                        </div>
                                        <div className='feature'>
                                            <h6 className='mb-2'>Feature</h6>
                                            <div className='d-flex'>
                                                <div className='calling m-0 me-2'>
                                                    <i class='fa fa-phone pe-1 text-white'></i> Call
                                                </div>
                                                <div className='chating m-0'>
                                                    <i class='fa fa-commenting pe-1 text-white'></i> Chat
                                                </div>
                                            </div>
                                        </div>
                                        <div className='rating'>
                                            <h6 className='mb-2'> User Rating:</h6>
                                            <div className='ratingArea'>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star"></i>
                                                <i class="fa fa-star-half-o"></i>
                                                <strong className='ps-2'>(4.5)</strong>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='description'>
                            <div className='aboutTitle mb-3'>About us</div>
                            <p>David Parker is a renowned astrologer known for his profound insights and accurate predictions. With years of experience, he has become a trusted guide for individuals seeking clarity in their life journey. His expertise lies in analyzing birth charts, deciphering planetary influences, and offering personalized guidance. </p>
                            <p>David's approach combines traditional astrological methods with a modern understanding, providing clients with practical and actionable advice. His commitment to authenticity and precision has garnered a loyal following. Whether navigating career choices, relationships, or personal development, David Parker's astrological consultations are illuminating, empowering individuals to make informed decisions and embrace their life's path with confidence.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AstrologerProfile;
