import React from 'react';
import '../services/css/chat-to-astrologer.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/autoplay';
import api, { baseUrl } from '../api/api';
import { Link } from 'react-router-dom';

const AstroTeam = ({data}) => {
    return (
        <div className="astrologer_wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="titleSec_wrapper">
                            <h2 className='heading'>Our <span> Astrologer</span></h2>
                            <h4 className='animatedLine'><span>&nbsp;</span></h4>
                            <p className='desc'>Dedicated astrologers bringing celestial insights for your well-being. <br />Trusted guidance for a brighter tomorrow.</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="astrologer_slider">
                            <Swiper
                                modules={[Navigation, Pagination, Autoplay]}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                loop={true}
                                spaceBetween={30}
                                slidesPerView={1}
                                // navigation={true}
                                pagination={{ clickable: true }}
                                breakpoints={{
                                    992: {
                                        slidesPerView: 3,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    290: {
                                        slidesPerView: 1,
                                    }
                                }}                            >

                                {data.map((team) => (
                                    <SwiperSlide key={team.id}>
                                        <div className='chatAstro mt-4 mb-4'>
                                            <div className='memberPic'>
                                                <img src={baseUrl + team.profileImage} alt="member" />
                                                <div className=''>
                                                    <div className='charges'><i className="fa fa-inr pe-1"></i>{team.charge}/Min</div>
                                                </div>
                                            </div>
                                            <div className='aboutMember'>
                                                <div className='name mb-2'><Link to="/astrologerProfile">{team.name}</Link></div>
                                                <div className='pb-2'>
                                                    <div className='role'><i className="fa fa-star pe-1"></i>{team.primarySkill}</div>
                                                </div>
                                                <div className='pb-2 exlan'>
                                                    <div className='experience'><i className="fa fa-clock-o pe-1"></i>{team.experienceInYears}+ Years</div>
                                                    <div className='language'>
                                                        <i className="fa fa-language pe-1"></i>
                                                        {team.languageKnown}
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <div className='calling m-0 me-2'>
                                                        <i class='fa fa-phone pe-1 text-white'></i> Call
                                                    </div>
                                                    <div className='chating m-0'>
                                                        <i class='fa fa-commenting pe-1 text-white'></i> Chat
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default AstroTeam;
