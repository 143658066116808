import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, FormControl, Button } from 'react-bootstrap';
import './css/product.css';
import Breadcrumb from '../components/breadcrumb';

import api, { baseUrl } from '../api/api';
import axios from 'axios';
const MallProduct = () => {
    const {id} = useParams();
    const [categoryList, setCategoryList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [fetchRecord] = useState(3);
    const [startIndex] = useState(0);
    const [filterValues, setfilterValues] = useState({
        search: '',
      })
    const handleChanageFilter = (name, value) => {
        setfilterValues((prev) => ({
          ...prev,
          [name]: value,
        }))
      }
    const fetchProduct = async () => {
        await api.post('/searchAstromallProductCategory', {productCategoryId: id, searchString: filterValues.search,})
        .then((resp) => {
            setProductList(resp.data.recordList);
        })
    }
    const fetchCategory = async () => {
        await api.post("/getproductCategory")
        .then((resp) => {
            setCategoryList(resp.data.recordList);
        })
    }
    useEffect(()=>{
        fetchProduct();
        fetchCategory();
    }, [id, filterValues])

    const data = {
        pageTitle: "Mall Products"
    }
    const topCategories = [
        'Accessories (10)',
        'Astrology (21)',
        'Candles (12)',
        'Crystals (22)',
        'Mystical (36)',
        'Tarot (14)',
    ];

    const ProductList = [
        {
            img: "/images/prod1.jpg",
            subheading: 'Gold Crown Treasure',
            price: '₹ 499.00/-'
        },
        {
            img: "/images/prod2.jpg",
            subheading: 'Gold Crown Treasure',
            price: '₹ 499.00/-'
        },
        {
            img: "/images/prod3.jpg",
            subheading: 'Gold Crown Treasure',
            price: '₹ 499.00/-'
        },
        {
            img: "/images/prod4.jpg",
            subheading: 'Gold Crown Treasure',
            price: '₹ 499.00/-'
        },
        {
            img: "/images/prod1.jpg",
            subheading: 'Gold Crown Treasure',
            price: '₹ 499.00/-'
        },
        {
            img: "/images/prod2.jpg",
            subheading: 'Gold Crown Treasure',
            price: '₹ 499.00/-'
        }
    ];
    const onSubmit = (event) => {
        event.preventDefault();
    }

    return (
        <div>
            <Breadcrumb {...data} />

            <section className="product_wrap bgtheme">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            {/* <div className='categoryTitle'>
                                <span>Category  <i className="fa fa-angle-right mx-2" aria-hidden="true"></i></span>
                                Category Name
                            </div> */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="searchBar">
                            <FormControl type="text" id="search" placeholder="Search" className="mr-sm-2 form-control" onChange={(e) => 
handleChanageFilter('search', e.target.value)} /> 
                            </div>
                        </div>
                    </div>
                    <div className="row productArea">
                        <div className="col-lg-9 col-md-8 col-sm-12 col-12">
                            <div className="row">
                                {productList.map((item, index) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="productBox">
                                            <div className="productImg">
                                                <img src={baseUrl + item.productImage} alt="product" className="img-fluid" />
                                            </div>
                                            <div className="productCont">
                                                <div className="productTitle">{item.name}</div>

                                                <div className='viewMoreBtn mt-2'>
                                                    <span className="price">₹ {item.amount} </span>
                                                    <Link to={`/mall-details/${item.id}`} className=''>View More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                            <div className="product_sidebar">
                                <div className="productCat">
                                    <div className="productCat_title">Product Categories</div>
                                    <ul>
                                        {categoryList.map((category, index) => (
                                            <li key={index}>
                                                <Link to={`/product/${category.id}`}>{category.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className='col-lg-12 col-md-12 col-sm-12 col-12 text-center'>
                            <div className="pagination">
                                <ul>
                                    <li className="prev"><Link to="#!">prev</Link></li>
                                    <li className="pagiActive"><Link to="#!">1</Link></li>
                                    <li><Link to="#!">2</Link></li>
                                    <li><Link to="#!">3</Link></li>
                                    <li className="as_next"><Link to="#!">next</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </div>
    );
};

export default MallProduct;
