import React, {useState, useEffect} from 'react';
import './css/index.css';
import Breadcrumb from '../components/breadcrumb';
import { Link } from 'react-router-dom';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/autoplay';

import api, { baseUrl } from '../api/api';
import axios from 'axios';

const Mall = () => {
    const [categoryList, setCategoryList] = useState([]);
    const [banners, setBanners] = useState([]);
    const [fetchRecord] = useState(3);
    const [startIndex] = useState(0);
    const fetchCategory = async () => {
        await api.post("/getproductCategory")
        .then((resp) => {
            setCategoryList(resp.data.recordList);
        })
    }
    const fetchBanner = async () => {
        await api.post("/getBanner")
        .then((resp) => {
            setBanners(resp.data.recordList);
        })
    }
    useEffect(()=>{
        fetchCategory();
        fetchBanner();
    }, [])
    const data = {
        pageTitle: "Mall Category"
    }

    return (
        <div>
            <Breadcrumb {...data} />

            <section className="mall_categories bgtheme">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-5">
                            <div className="categoriesBanner">
                                <Swiper
                                    modules={[Navigation, Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 400000000,
                                        disableOnInteraction: false,
                                    }}
                                    loop={true}
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    navigation={false}
                                    pagination={{ clickable: true }}
                                >
                                    {banners.map((banner, index) =>(
                                    <SwiperSlide key={index}>
                                        <div className="item">
                                            <img src={baseUrl+banner.bannerImage} className="img-fluid rounded-3"
                                                alt="slider" />
                                        </div>
                                    </SwiperSlide>
                                    )
                                    )}
                                </Swiper>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    {/* <div className='categoryTitle'>
                                        <span>Mall Category</span>
                                        
                                    </div> */}
                                </div>
                                {categoryList.map((item, index) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="category_box">
                                            <Link to={`/product/${item.id}`}>
                                                <div className="categoryThumb">
                                                    <span className="overlay-icon">  </span>
                                                    <img src={baseUrl + item.categoryImage} className="img-fluid" alt="categori" />
                                                </div>
                                                <div className="title"><Link to={`/product/${item.id}`}>{item.name}</Link></div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </section>



        </div>
    );
};

export default Mall;
