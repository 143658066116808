import axios from "axios";

export default axios.create({
  // baseURL: "http://127.0.0.1:8000/api",
  baseURL: "https://admin.astromaya.in/api",
  headers: {
    "Content-type": "application/json"
  }
});
export const baseUrl = "https://admin.astromaya.in/";
// export const config = {
//     method: 'post',
//     url: `${BASE_URL}/login`,
//     headers: { 
//       'Content-Type': 'multipart/form-data'.  <----- Add this line in your axios header
//     },
//     data : formData
//   };
