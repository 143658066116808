import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css-link';

import Header from './components/header';
import Home from './home';
import About from './about';

import MallCategory from './mall';
import MallProduct from './mall/product';
import MallDetails from './mall/mall-details';

import Horoscope from './horoscope';

import Services from './services';
import TarotCard from './services/tarot-card';
import Palm from './services/palm';
import Numerology from './services/numerology';
import EnergyHealing from './services/energy-healing';
import Kundalimaking from './services/kundli-making';
import Kundalimatching from './services/kundli-matching';
import CallToAstrologer from './services/call-to-astrologer';
import ChatToAstrologer from './services/chat-to-astrologer';
import AstrologerProfile from './astrologer-profile/astrologer-profile';
import Blog from './blog';
import BlogDetail from './blog/blog-detail';

import Contact from './contact';
import NotFound from './not-found';
import Bio from './bio';
import Vastu from './vastu';

import Footer from './components/footer';

import PrivacyPolicy from './privacy-policy';
import TermsAndCondition from './terms-and-condition';

import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        <Route path="/mall" element={<MallCategory />} />
        <Route path="/product/:id" element={<MallProduct />} />
        <Route path="/mall-details/:id" element={<MallDetails />} />

        <Route path="/horoscope" element={<Horoscope />} />

        <Route path="/services" element={<Services />} />
        <Route path="/services/tarot-card" element={<TarotCard />} />
        <Route path="/services/palm" element={<Palm />} />
        <Route path="/services/numerology" element={<Numerology />} />
        <Route path="/services/energy-healing" element={<EnergyHealing />} />
        <Route path="/services/kundli-making" element={<Kundalimaking />} />
        <Route path="/services/kundli-matching" element={<Kundalimatching />} />
        <Route path="/services/call-to-astrologer" element={<CallToAstrologer />} />
        <Route path="/services/chat-to-astrologer" element={<ChatToAstrologer />} />
        <Route path="/astrologerProfile" element={<AstrologerProfile />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-detail/:id" element={<BlogDetail />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="*" element={<NotFound />} />

        <Route path="/bio" element={<Bio />} />
        <Route path="/vastu" element={<Vastu />} /> 

        
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />       
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}
export default App;