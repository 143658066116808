import React from 'react';
import './index.css';
import Breadcrumb from '../components/breadcrumb';

const Horoscope = () => {
    const data = {
        pageTitle: "Horoscope"
    }

    const SignData = [
        {
            id: 1,
            img: '/images/signs/icon1.png',
            title: 'Aries Horoscope 2023',
            desc: 'In 2023, Aries, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 2,
            img: '/images/signs/icon2.png',
            title: 'Taurus Horoscope 2023',
            desc: 'In 2023, Aries, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 3,
            img: '/images/signs/icon3.png',
            title: 'Gemini Horoscope 2023',
            desc: 'In 2023, Gemini, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 4,
            img: '/images/signs/icon4.png',
            title: 'Cancer Horoscope 2023',
            desc: 'In 2023, Cancer, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 5,
            img: '/images/signs/icon5.png',
            title: 'Leo Horoscope 2023',
            desc: 'In 2023, Leo, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 6,
            img: '/images/signs/icon6.png',
            title: 'Virgo Horoscope 2023',
            desc: 'In 2023, Aries, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 7,
            img: '/images/signs/icon7.png',
            title: 'Libra Horoscope 2023',
            desc: 'In 2023, Libra, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 8,
            img: '/images/signs/icon8.png',
            title: 'Scorpio Horoscope 2023',
            desc: 'In 2023, Scorpio, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 9,
            img: '/images/signs/icon9.png',
            title: 'Sagittairus Horoscope 2023',
            desc: 'In 2023, Sagittairus, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 10,
            img: '/images/signs/icon10.png',
            title: 'Leo Capricorn 2023',
            desc: 'In 2023, Leo, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 11,
            img: '/images/signs/icon11.png',
            title: 'Aquarius Horoscope 2023',
            desc: 'In 2023, Aquarius, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        },
        {
            id: 12,
            img: '/images/signs/icon12.png',
            title: 'Pisces Horoscope 2023',
            desc: 'In 2023, Pisces, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; its a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.'
        }
    ]
    return (
        <div>
            <Breadcrumb {...data} />

            <div className='yearlyScope bgtheme'>
                <div className='yearlyScopeSec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='horoscopeImg'>
                                    <img className="img-fluid" src="/images/horoscope.jpg" alt="horoscope" />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='yearlyScopeTitle'>Yearly horoscope 2023 predictions for all zodiac signs</div>
                                <p className='mb-2'>In 2023, the cosmos paints diverse portraits for each zodiac sign. Aries, embrace career shifts. Taurus, cherish love's renewal. Gemini, prioritize self-discovery. Cancer, welcome financial abundance. Leo, forge meaningful connections. Virgo, focus on health and career synergy.</p>
                                <p className='mb-2'>Libra, seek personal harmony. Scorpio, navigate transformative energies. Sagittarius, embark on new adventures. Capricorn, flourish in professional endeavors. Aquarius, nurture relationships. Pisces, harness creativity. Collective cosmic energies urge adaptability and growth. </p>
                                <p className='mb-2'>Navigate challenges with resilience; reap rewards through introspection. The stars foretell a transformative journey, offering unique opportunities for personal and professional evolution in 2023. Embrace the celestial dance, and let destiny unfold.</p>
                            </div>
                        </div>
                        <div className='row'>
                            {SignData.map((item, index) => (
                                <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <div className='signsSec'>
                                        <img src="/images/signs/icon1.png" alt="" />
                                        <div className='signCnt'>
                                            <div className='title'>Aries Horoscope 2023</div>
                                            <p>In 2023, Aries, brace for transformative energies. Your career takes a leap, and financial prospects bloom. Relationships flourish, but communication is key. Embrace change with open arms; it's a catalyst for personal growth. Health-wise, prioritize well-being routines. Stay vigilant yet adaptable, as opportunities may arise unexpectedly. Trust your instincts and nurture your ambitions. This dynamic year promises Aries a blend of challenges and triumphs. Seize the moment and manifest your aspirations.</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Horoscope;
